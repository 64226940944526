import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
/**
 * @method validateAdjustFile
 * @method validateAdjustFile
 */
export default {
  KEY_COOKIE_USER: 'CRMUser',
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get(this.KEY_COOKIE_USER).Token
  },
  agency_id: function () {
    return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      ? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      : this.config().agency_id
  },
  app_id: function () {
    return Cache.get(this.KEY_COOKIE_USER).AppId
  },
  validateAdjustFile: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      AdjustPointFile: file,
    }

    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusearn/validate/validateAdjustFile'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  validateAdjustFileByContactNumber: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      AdjustPointFile: file,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/validate/validateAdjustFileByContactNumber'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  validatePromoCode: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      quantity: '0',
      checkQuota: false,
      serialCodeFile: file,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusburn/validate/promocode'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postAdjustPoint: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      AdjustPointFile: file,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusearn/adjust/adjustpoint'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postAdjustPointByContactNumber: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      AdjustPointFile: file,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/adjust/adjustpointByContactNumber'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getLogPoint: function (skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/get/adjustpointlog?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getLogPointDetail: function (partionkey, filename, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/get/adjustpointlogDetail?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&partionkey=' +
      partionkey +
      '&filename=' +
      filename +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getLogPointDetailExportExcel: function (partionkey, filename, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/get/adjustpointlogDetail?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&partionkey=' +
      partionkey +
      '&filename=' +
      filename +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  validateCompanyUserFile: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      CompanyUserFile: file,
    }

    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusimport/validate_import_companyuser'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCompanyUser: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      CompanyUserFile: file,
    }

    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusimport/import_companyuser'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getLogImportUser: function (skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusimport/get/Import_companyuser_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getLogImportUserDetail: function (partionkey, filename, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusimport/get/Import_companyuser_detail_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&partionkey=' +
      partionkey +
      '&filename=' +
      filename +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getSearchImportUser: function (obj) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/companyusers?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&verifyCode1=' +
      obj.verifycode1 +
      '&verifyCode2=' +
      obj.verifycode2 +
      '&firstName=' +
      obj.first_name +
      '&lastName=' +
      obj.last_name +
      '&tel=' +
      obj.tel +
      '&email=' +
      obj.email

    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getSearchUserInfoFunc: function (
    firstName,
    lastName,
    contactNumber,
    email,
    referenceInfo,
    referenceInfo2,
    verifyCode1,
  ) {
    var name = firstName.length >= 1 ? firstName : '-'
    var surname = lastName.length >= 1 ? lastName : '-'
    var mobilenumber = contactNumber.length >= 1 ? contactNumber : '-'
    var emailaddress = email.length >= 1 ? email : '-'
    var refid = referenceInfo.length >= 1 ? referenceInfo : '-'
    var reference2 = referenceInfo2.length >= 1 ? referenceInfo2 : '-'
    var verifycode1 = verifyCode1.length >= 1 ? verifyCode1 : '-'

    var strHost =
      this.config().bzbsModuleUrl + '/crmplusdatasci/searchUserInfoFunc?appid='

    var strUrl =
      strHost +
      this.app_id() +
      '&name=' +
      name +
      '&surname=' +
      surname +
      '&birthday=' +
      '-' +
      '&mobilenumber=' +
      mobilenumber +
      '&email=' +
      emailaddress +
      '&refid=' +
      refid +
      '&reference2=' +
      reference2 +
      '&verifycode1=' +
      verifycode1 +
      '&gender=' +
      '-' +
      '&agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getuserByUserId: function (userId) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/user?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&userId=' +
      userId
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  postUpdateUserByUserId: function (file) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      userId: file.UserId,
      firstName: file.FirstName,
      lastName: file.LastName,
      birthDate: file.BirthDate,
      contactNumber: file.ContactNumber,
      email: file.Email,
      point: file.Point,
      gender: file.Gender,
      referenceInfo: file.ReferenceInfo,
      referenceInfo2: file.ReferenceInfo2,
      termAndCondition: file.TermAndCondition,
      dataPrivacy: file.DataPrivacy,
      lineMarketing: file.LineMarketing,
      smsMarketing: file.SMSMarketing,
      emailMarketing: file.EmailMarketing,
      active: file.Active,
      levelName: file.LevelName,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/user'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getcrmPlusUserLevel: function (mode) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmpluslevel/office_list?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&mode=' +
      mode
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getcrmPlusLevel: function (mode) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmpluslevel/list?agencyId=' +
      this.agency_id() +
      '&mode=' +
      mode
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  validateUploadCompanyUserFile: function (file) {
    var params = {
      CompanyUserFile: file,
    }
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusimport/upload_companyuser_file?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  confirmCompanyuserFile: function (key) {
    var params = {
      key: key,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusimport/confirm_companyuser_file?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  removeCompanyuserFile: function (rk, pk) {
    var params = {
      rk: rk,
      pk: pk,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusimport/remove_companyuser_file?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params)
  },
  deleteCompanyuser: function (key) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      rowKey: key,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/companyuser'
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params)
  },
  updateCompanyUser: function (obj) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      rowKey: obj.RowKey,
      verifyCode1: obj.VerifyCode1,
      verifyCode2: obj.VerifyCode2,
      verifyCode3: obj.VerifyCode3,
      verifyCode4: obj.VerifyCode4,
      firstName: obj.FirstName,
      lastName: obj.LastName,
      birthDate: obj.BirthDate,
      tel: obj.Tel,
      email: obj.Email,
      gender: obj.Gender,
      point: obj.Point,
      level: obj.LevelName,
      password: obj.Password,
      active: obj.Active,
      user_id: obj.UserId,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/companyuser?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  validateAdjustPoint: function (file, type, appId) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      AdjustPointFile: file,
      type: type,
      appId: appId,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/file/upload_adjust_point?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  confirmAdjustPoint: function (key) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/' +
      key +
      '/confirm_adjust_point?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), null)
  },
  getAdjustPointLog: function (top, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/get/adjustpoint_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=' +
      top +
      '&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getAdjustPointLogDetail: function (top, skip, key) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/' +
      key +
      '/adjustpoint_log_detail?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=' +
      top +
      '&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  uploadSpending: function (file, type, appId) {
    var params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      SpendingFile: file,
      type: type,
      appId: appId,
    }

    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusspending/file/upload_spending_file'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getLogSpending: function (skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusspending/get/import_spending_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getAdjustSpendingLog: function (top, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusspending/get/import_spending_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&top=' +
      top +
      '&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getLogSpendingDetail: function (partionkey, filename, skip) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusspending/get/import_spending_detail_log?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&partionkey=' +
      partionkey +
      '&filename=' +
      filename +
      '&top=0&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  removeSpendingFile: function (rk, pk) {
    var params = {
      rk: rk,
      pk: pk,
    }

    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusspending/remove_spending_file'
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params)
  },
  confirmAdjustSpending: function (rk, pk) {
    var params = {
      agencyId: this.agency_id(),
      rk: rk,
      pk: pk,
      key: rk,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusspending/confirm_spending_file'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
