<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row paj-custom-row">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12
                pb-4
                wrapper-dashboard
                custom-wrapper-dashboard
              "
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    mr-2
                    text-title-point-adjustment
                  "
                >
                  <img
                    class="mr-2"
                    :src="require('@/assets/images/fil022.svg')"
                    alt=""
                  />{{ lbl['import-search-menu'] }}
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-point-adjustment">
              <div class="d-flex wrapper-select-header">
                <div @click="goto('ImportUser')" class="select-header">
                  {{ lbl['import-user-menu'] }}
                </div>
                <div
                  @click="goto('ImportUserLog')"
                  class="select-header select-hd-right"
                >
                  {{ lbl['import-log-menu'] }}
                </div>
                <div class="select-header select-hd-right active">
                  {{ lbl['import-search-menu'] }}
                </div>
              </div>
              <div class="wrapper-content">
                <div class="around-content-import underline">
                  <div class="search-box">
                    <a-form :form="form" class="search-form">
                      <div class="row">
                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-verifycode1']
                          }}</label>
                          <input
                            v-model="filters.verifycode1"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-verifycode2']
                          }}</label>
                          <input
                            v-model="filters.verifycode2"
                            type="text"
                            class="form-control"
                          />
                        </div>

                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-name']
                          }}</label>
                          <input
                            v-model="filters.name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-lastname']
                          }}</label>
                          <input
                            v-model="filters.last_name"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-tel']
                          }}</label>
                          <input
                            v-model="filters.tel"
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="
                            col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12
                            mb-2
                          "
                        >
                          <label class="text-left w-100">{{
                            lbl['import-search-email']
                          }}</label>
                          <input
                            v-model="filters.email"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 endbtn mt-2">
                          <a-button
                            type="mx-2"
                            class="searchbtn width-120"
                            @click="handleSearch()"
                          >
                            {{ lbl['import-search-button'] }}
                          </a-button>
                        </div>
                      </div>
                    </a-form>
                  </div>
                </div>
                <div class="around-content-import">
                  <div class="search-box">
                    <div class="table-box">
                      <div class="tableheader">
                        <span class="titletable">{{
                          lbl['import-search-menu']
                        }}</span>
                        <button
                          class="button-import"
                          @click="isPH ? exportExcelPH() : exportExcel()"
                        >
                          <img
                            class="img-fluid ico-excel"
                            :src="require('@/assets/images/DownloadedFile.svg')"
                            alt=""
                          />
                          <span class="pl-2">{{
                            lbl['import-search-export-excel-button']
                          }}</span>
                        </button>
                      </div>
                      <a-table
                        row-key="RowKey"
                        :columns="isPH ? columnsDetailPH : columnsDetail"
                        :data-source="dataDetail"
                        :pagination="{
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                          pageSizeOptions: pageSizeOptions,
                          pageSize: defaultPageSize,
                          defaultCurrent: 1,
                          showSizeChanger: true,
                          hideOnSinglePage: false,
                          onChange: onChangePageDetail,
                        }"
                      >
                        <template slot="isdata_user_id" slot-scope="text">
                          <span>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="isdata_name" slot-scope="text, record">
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].FirstName"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_lastname"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].LastName"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_verifycode1"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].VerifyCode1"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_verifycode2"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].VerifyCode2"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_verifycode3"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].VerifyCode3"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_verifycode4"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].VerifyCode4"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="isdata_tel" slot-scope="text, record">
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Tel"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="isdata_email" slot-scope="text, record">
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Email"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="isdata_point" slot-scope="text, record">
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Point"
                            style="margin: -5px 0; min-width: 100px"
                            :disabled="isBeeBanefit"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="isdata_level" slot-scope="text, record">
                          <a-select
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].LevelName"
                            style="margin: -5px 0; min-width: 100px"
                          >
                            <a-select-option
                              v-for="level in userLevelList"
                              :key="level.label"
                            >
                              {{ level.label }}
                            </a-select-option>
                          </a-select>
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template
                          slot="isdata_active"
                          slot-scope="text, record"
                        >
                          <a-input
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Active"
                            style="margin: -5px 0; min-width: 100px"
                          />
                          <span v-else>
                            {{ text ? text : '' }}
                          </span>
                        </template>
                        <template slot="sex" slot-scope="text, record">
                          <a-select
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Gender"
                            style="margin: -5px 0; min-width: 100px"
                          >
                            <a-select-option value="male">
                              {{ lbl['Male'] }}
                            </a-select-option>
                            <a-select-option value="female">
                              {{ lbl['Female'] }}
                            </a-select-option>
                            <a-select-option value="other">
                              {{ lbl['Other'] }}
                            </a-select-option>
                          </a-select>
                          <span v-else>
                            <span v-if="record.Gender == 'male'">{{
                              lbl['Male']
                            }}</span>
                            <span v-else-if="record.Gender == 'female'">{{
                              lbl['Female']
                            }}</span>
                            <span v-else>{{ lbl['Other'] }}</span>
                          </span>
                        </template>
                        <template slot="date" slot-scope="text, record">
                          <a-date-picker
                            v-if="editableData == record._id"
                            format="DD/MMM/YYYY"
                            v-model="dataDetail[record.key - 1].BirthDate"
                            style="margin: -5px 0; min-width: 150px"
                          />
                          <span v-else>
                            {{ text ? text : '-' }}
                          </span>
                        </template>
                        <template slot="status" slot-scope="text, record">
                          <a-switch
                            v-if="editableData == record._id"
                            v-model="dataDetail[record.key - 1].Active"
                          />
                          <span v-else>
                            {{ text ? lbl['active'] : lbl['inactive'] }}
                          </span>
                        </template>
                        <template slot="action" slot-scope="text, record">
                          <span v-if="!record.UserId">
                            <a-button
                              v-if="editableData == record._id"
                              type="primary"
                              style="margin: -5px 0"
                              @click="() => onSave(record)"
                            >
                              {{ lbl['account-setup-button-save'] }}
                            </a-button>
                            <div v-else class="box-action d-flex">
                              <a-icon
                                :style="{
                                  fontSize: '18px ',
                                }"
                                type="form"
                                class="mr-1"
                                @click="() => onEdit(record._id)"
                              />
                              <a-icon
                                :style="{
                                  color: '#ff5252',
                                  fontSize: '18px ',
                                }"
                                theme="filled"
                                type="delete"
                                class="ml-1"
                                @click="onConfirmDelete(record.RowKey)"
                              />
                            </div>
                          </span>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
      :desciption="alertModalSetting.desciption"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsImport from '@/core/Import/service/BzbsImport'
import XLSX from 'xlsx'
import moment from 'moment'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'

const columnsDetail = [
  {
    title: 'UserID',
    dataIndex: 'UserId',
    key: 'view-code-user-id',
    width: '250px',
    scopedSlots: {
      customRender: 'isdata_user_id',
    },
  },
  {
    title: 'Name',
    dataIndex: 'FirstName',
    key: 'import-search-name',
    width: '100px',
    scopedSlots: {
      customRender: 'isdata_name',
    },
  },
  {
    title: 'LastName',
    dataIndex: 'LastName',
    key: 'import-search-lastname',
    width: '100px',
    scopedSlots: {
      customRender: 'isdata_lastname',
    },
  },
  {
    title: 'VerifyCode1',
    dataIndex: 'VerifyCode1',
    key: 'import-search-verifycode1',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode1',
    },
  },
  {
    title: 'VerifyCode2',
    dataIndex: 'VerifyCode2',
    width: '150px',
    key: 'import-search-verifycode2',
    scopedSlots: {
      customRender: 'isdata_verifycode2',
    },
  },
  {
    title: 'VerifyCode3',
    dataIndex: 'VerifyCode3',
    key: 'import-search-verifycode3',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode3',
    },
  },
  {
    title: 'VerifyCode4',
    dataIndex: 'VerifyCode4',
    width: '150px',
    key: 'import-search-verifycode4',
    scopedSlots: {
      customRender: 'isdata_verifycode4',
    },
  },
  {
    title: 'Tel',
    dataIndex: 'Tel',
    key: 'imp-usr-dtl-tel',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_tel',
    },
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'import-search-email',
    scopedSlots: {
      customRender: 'isdata_email',
    },
  },
  {
    title: 'Gender',
    dataIndex: 'Gender',
    key: 'import-search-gender',
    scopedSlots: {
      customRender: 'sex',
    },
  },
  {
    title: 'BirthDate',
    dataIndex: 'BirthDate',
    key: 'import-search-birthday',
    width: '120px',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'Point',
    dataIndex: 'Point',
    key: 'import-search-point',
    scopedSlots: {
      customRender: 'isdata_point',
    },
  },
  {
    title: 'UserLevel',
    dataIndex: 'LevelName',
    key: 'usr-level',
    scopedSlots: {
      customRender: 'isdata_level',
    },
  },
  {
    title: 'Import status',
    dataIndex: 'Active',
    key: 'import-log-status-header',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    dataIndex: 'Action',
    key: '',
    align: 'center',
    width: '100px',
    scopedSlots: { customRender: 'action' },
  },
]

const columnsDetailPH = [
  {
    title: 'Name',
    dataIndex: 'FirstName',
    key: 'import-search-name',
    width: '100px',
    scopedSlots: {
      customRender: 'isdata_name',
    },
  },
  {
    title: 'LastName',
    dataIndex: 'LastName',
    key: 'import-search-lastname',
    width: '100px',
    scopedSlots: {
      customRender: 'isdata_lastname',
    },
  },
  {
    title: 'BirthDate',
    dataIndex: 'BirthDate',
    key: 'import-search-birthday',
    width: '120px',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'Gender',
    dataIndex: 'Gender',
    key: 'import-search-gender',
    scopedSlots: {
      customRender: 'sex',
    },
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'import-search-email',
    scopedSlots: {
      customRender: 'isdata_email',
    },
  },
  {
    title: 'Tel',
    dataIndex: 'Tel',
    key: 'imp-usr-dtl-tel',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_tel',
    },
  },
  {
    title: 'Point',
    dataIndex: 'Point',
    key: 'import-search-point',
    scopedSlots: {
      customRender: 'isdata_point',
    },
  },
  {
    title: 'VerifyCode1',
    dataIndex: 'VerifyCode1',
    key: 'import-search-verifycode1',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode1',
    },
  },
  {
    title: 'VerifyCode2',
    dataIndex: 'VerifyCode2',
    key: 'import-search-verifycode2',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode2',
    },
  },
  {
    title: 'VerifyCode3',
    dataIndex: 'VerifyCode3',
    key: 'import-search-verifycode3',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode3',
    },
  },
  {
    title: 'VerifyCode4',
    dataIndex: 'VerifyCode4',
    key: 'import-search-verifycode4',
    width: '150px',
    scopedSlots: {
      customRender: 'isdata_verifycode4',
    },
  },
  {
    title: '',
    dataIndex: 'Action',
    key: '',
    align: 'center',
    width: '100px',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'importUserSearch',
  mixins: [Mixin],
  components: {
    Header,
    ConfirmModal,
    AlertModal,
  },
  data: function () {
    return {
      isPH: Vue.bzbsConfig.businessRule.webFor.PH,
      data: [],
      total: null,
      columnsDetail,
      columnsDetailPH,
      dataDetail: [],
      dataExport: [],
      totalDetail: null,
      currentDetail: 1,
      filters: {
        verifycode1: '',
        verifycode2: '',
        name: '',
        last_name: '',
        tel: '',
        email: '',
      },
      pageSize: 30,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      editableData: '',
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        details: '',
        modalType: '',
        isOpenModal: false,
      },
      userLevelList: [],
      isBeeBanefit: false,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'import-user-search',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'import_member_page',
      'import_member_search_imported',
      'view_import_member_search_imported_member',
      'on view',
    )
    this.setLocale()
    this.getLevelList()
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
      if (this.isBeeBanefit && this.$route.query.update) {
        this.handleSearch()
      }
    }
  },
  methods: {
    setLocale() {
      if (this.isPH) {
        _.forEach(this.columnsDetailPH, column => {
          column.title = this.lbl[column.key]
        })
      } else {
        _.forEach(this.columnsDetail, column => {
          column.title = this.lbl[column.key]
        })
      }
    },
    onChangePageDetail(page) {
      this.currentDetail = page
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleSearch() {
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member_search_imported',
        'click_import_member_search_imported_member_search',
        'on click',
      )
      this.editableData = ''
      this.currentDetail = 1
      this.getSearchImportUser()
    },
    getLevelList() {
      this.handleLoading(true)

      return new Promise(resolve => {
        BzbsUserProfile.getLevelList()
          .then(res => {
            if (res.data.length > 0) {
              res.data.map(item => {
                var obj = {
                  label: item.UserLevelName,
                  value: item.UserLevel,
                }
                this.userLevelList.push(obj)
              })
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLevelList error', error)
            resolve(error)
          })
      })
    },
    getSearchImportUser() {
      this.handleLoading(true)
      let obj = {
        verifycode1: this.filters.verifycode1,
        verifycode2: this.filters.verifycode2,
        first_name: this.filters.name,
        last_name: this.filters.last_name,
        tel: this.filters.tel,
        email: this.filters.email,
      }

      return new Promise(resolve => {
        BzbsImport.getSearchImportUser(obj)
          .then(res => {
            this.handleLoading(false)
            this.totalDetail = res.data.length
            this.dataExport = res.data
            let key = 1
            this.dataDetail = _.forEach(res.data, item => {
              item.BirthDate = item.BirthDate
                ? moment.unix(item.BirthDate).format('DD/MMM/YYYY')
                : null
              item.key = key
              key++
            })

            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getSearchImportUser error', error)
            resolve(error)
          })
      })
    },
    exportExcel() {
      console.log('exportExcel')
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member_search_imported',
        'click_import_member_search_imported_member_export',
        'on click',
      )
      const data = _.map(this.dataExport, item => {
        let birth = ''
        let sex = ''

        let locale = Locale.getLocaleCode()
        if (locale != 'th') locale = 'en'
        moment.locale(locale)

        if (item.BirthDate != null && item.BirthDate != '-') {
          var format = item.BirthDate
          birth = new Date(format)
        } else {
          birth = ''
        }

        if (item.Gender == 'male') {
          sex = this.lbl['Male']
        } else if (item.Gender == 'female') {
          sex = this.lbl['Female']
        } else {
          sex = this.lbl['Other']
        }

        var user_id = this.lbl['import-search-userid']
        var verifycode1 = this.lbl['import-search-verifycode1']
        var verifycode2 = this.lbl['import-search-verifycode2']
        var verifycode3 = this.lbl['import-search-verifycode3']
        var verifycode4 = this.lbl['import-search-verifycode4']
        var name = this.lbl['import-search-name']
        var last_name = this.lbl['import-search-lastname']
        var tel = this.lbl['import-search-tel']
        var email = this.lbl['import-search-email']
        var gender = this.lbl['import-search-gender']
        var birth_date = this.lbl['import-search-birthday']
        var point = this.lbl['import-search-point']
        var user_level = this.lbl['import-search-userlevel']

        return {
          [user_id]: item.UserId,
          [name]: item.FirstName,
          [last_name]: item.LastName,
          [verifycode1]: item.VerifyCode1,
          [verifycode2]: item.VerifyCode2,
          [verifycode3]: item.VerifyCode3,
          [verifycode4]: item.VerifyCode4,
          [tel]: item.Tel,
          [email]: item.Email,
          [gender]: sex,
          [birth_date]: birth,
          [point]: { t: 'n', v: item.Point, z: '0' },
          [user_level]: item.LevelName,
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        var wscols = [
          { wch: 44 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 12 },
          { wch: 12 },
          { wch: 7 },
          { wch: 15 },
          { wch: 12 },
          { wch: 15 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          var ws_cols = [
            { wch: 44 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 12 },
            { wch: 12 },
            { wch: 7 },
            { wch: 15 },
            { wch: 12 },
            { wch: 12 },
            { wch: 15 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
    },
    exportExcelPH() {
      console.log('exportExcelPH')
      console.log('exportExcel')
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member_search_imported',
        'click_import_member_search_imported_member_export',
        'on click',
      )
      const data = _.map(this.dataExport, item => {
        let birth = ''
        let sex = ''

        let locale = Locale.getLocaleCode()
        if (locale != 'th') locale = 'en'
        moment.locale(locale)

        if (item.BirthDate != null && item.BirthDate != '-') {
          var format = item.BirthDate
          birth = new Date(format)
        } else {
          birth = ''
        }

        if (item.Gender == 'male') {
          sex = this.lbl['Male']
        } else if (item.Gender == 'female') {
          sex = this.lbl['Female']
        } else {
          sex = this.lbl['Other']
        }

        var verifycode1 = this.lbl['import-search-verifycode1']
        var verifycode2 = this.lbl['import-search-verifycode2']
        var verifycode3 = this.lbl['import-search-verifycode3']
        var verifycode4 = this.lbl['import-search-verifycode4']
        var name = this.lbl['import-search-name']
        var last_name = this.lbl['import-search-lastname']
        var tel = this.lbl['import-search-tel']
        var email = this.lbl['import-search-email']
        var gender = this.lbl['import-search-gender']
        var birth_date = this.lbl['import-search-birthday']
        var point = this.lbl['import-search-point']

        return {
          [name]: item.FirstName,
          [last_name]: item.LastName,
          [birth_date]: birth,
          [gender]: sex,
          [email]: item.Email,
          [tel]: item.Tel,
          [point]: { t: 'n', v: item.Point, z: '0' },
          [verifycode1]: item.VerifyCode1,
          [verifycode2]: item.VerifyCode2,
          [verifycode3]: item.VerifyCode3,
          [verifycode4]: item.VerifyCode4,
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        var wscols = [
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 10 },
          { wch: 25 },
          { wch: 10 },
          { wch: 10 },
          { wch: 25 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          var ws_cols = [
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 10 },
            { wch: 25 },
            { wch: 10 },
            { wch: 10 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
    },
    onEdit(key) {
      console.log('onEdit : ', key)
      this.editableData = key
    },
    onSave(record) {
      if (record.BirthDate) {
        record.BirthDate = moment(record.BirthDate).format('X')
      }
      this.editableData = null
      return new Promise(resolve => {
        BzbsImport.updateCompanyUser(record)
          .then(res => {
            if (res.data.success) {
              this.handleLoading(false)
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['user-role-manage-edit-success-message'],
                'success',
                true,
              )
              this.handleSearch()
            } else {
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                res.data.message
                  ? res.data.message
                  : this.lbl['Error occurred while processing your request.'],
                'error',
                true,
              )
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message
                ? error.response.data.error.message
                : this.lbl['Error occurred while processing your request.'],
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    onConfirmDelete(rowKey) {
      this.confirmModalAction(
        this.lbl['user-delete-header'],
        this.lbl['user-delete-details'],
        'danger',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.onDelete(rowKey)
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },
    onDelete(rowKey) {
      console.log('onDelete : ', rowKey)
      return new Promise(resolve => {
        BzbsImport.deleteCompanyuser(rowKey)
          .then(res => {
            this.handleLoading(false)
            if (this.isBeeBanefit) {
              this.alertModalActionBeeBenefit(
                this.lbl['alert-box-success-header'],
                this.lbl['user-role-manage-edit-success-message'],
                'success',
                true,
              )
            } else {
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['delete-user-success-message'],
                'success',
                true,
              )
            }
            this.handleSearch()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            resolve(error)
          })
      })
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            location.reload()
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    alertModalActionBeeBenefit(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
          window.location = window.location.href + '?update=true'
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            location.reload()
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },

    //onlyNumber
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    goto(page) {
      if (page == 'ImportUser') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member_search_imported',
          'click_import_member',
          'on click',
        )
      } else if (page == 'ImportUserLog') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member_search_imported',
          'click_import_member_activity_log',
          'on click',
        )
      }
      this.$router.push({ name: page })
    },
  },
}
</script>

<style lang="scss">
.search-box {
  .endbtn {
    display: flex;
    align-items: flex-end;
    button.searchbtn {
      border-radius: 6px;
      background-color: #f9a601;
      color: #fff;
      border: 1px solid #f9a601;
    }
  }
  .tableheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .titletable {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
    }
    .button-import {
      padding: 4px 16px;
      border-radius: 6px;
      border: solid 1px #389e0d;
      color: #389e0d;
      background-color: #fff;
    }
  }
}
.wrap-importUserSearch {
  .box-pagination {
    margin-top: -52px;
    margin-bottom: 20px;
    padding-top: 0;
  }

  .box-pagination .show-page {
    margin-top: 10px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;
  }

  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  .form-control {
    height: 40px;
    font-size: 16px;
  }

  .search-box {
    border-left: 1px solid #aaa;
    border-top: 2px solid black;
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    border-radius: 0.5em;
  }

  .text-left {
    text-align: left;
  }

  .width-120 {
    width: 120px;
  }

  .ico-excel {
    background-color: #217346;
    height: 1.5vw;
    padding: 0.25vw;
  }

  .div-import {
    float: right;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  .form-button {
    text-align: right;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .box-pagination {
      display: inline-grid;
      width: 100%;
      text-align: end;
      margin: 0 0 20px 0;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ant-btn-group {
      display: inline-grid;
    }

    .ant-btn-group .ant-btn {
      border-style: solid !important;
      border-color: #6c757d !important;
      border-radius: 0 !important;
      margin-bottom: 5px;
    }

    .box-pagination {
      display: inline-grid;
      width: 100%;
      text-align: end;
      margin: 0 0 20px 0;
    }

    .form-button {
      text-align: right;
    }

    .button-import {
      width: 48% !important;
    }

    .width-120 {
      width: 41% !important;
    }

    .ico-excel {
      width: 20%;
      height: 25px;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .box-pagination {
      display: inline-grid;
      width: 100%;
      text-align: end;
      margin: 0 0 20px 0;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .box-pagination .show-page {
      text-align: end;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .ico-excel {
      width: 20%;
      height: 25px;
    }

    .form-button {
      text-align: left;
    }

    .ml-2,
    .mx-2 {
      margin-left: 0rem !important;
    }
  }

  //แนวนอน mobile (iPhone x)
  @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
    .box-pagination {
      display: inline-grid;
      width: 100%;
      text-align: end;
      margin: 0 0 20px 0;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .box-pagination .show-page {
      text-align: end;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .ico-excel {
      width: 20%;
      height: 25px;
    }

    .button-import {
      width: auto !important;
    }

    .width-120 {
      width: auto !important;
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
    .ico-excel {
      width: 20%;
      height: 25px;
    }

    .button-import {
      width: auto !important;
    }

    .width-120 {
      width: auto !important;
    }

    .ml-2,
    .mx-2 {
      margin-left: 0rem !important;
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ico-excel {
      width: 20%;
      height: 25px;
    }
  }
}
</style>
<style lang="scss">
@import '../ImportBatchPoint/AdjustPoint.scss';
@import '@/style/base/common.scss';

.around-content-import {
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
@import '../ImportBatchPoint/AdjustPointScoped.scss';
.around-content-import {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  &.underline {
    border-bottom: 2px solid #f5f5f5;
  }
}
.custom-wrapper-dashboard {
  padding: 16px 30px;
}
.wrapper-select-header {
  padding: 0 30px !important;
}
.around-content-import {
  padding: 14px 30px !important;
}
</style>
